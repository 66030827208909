import React, { ReactNode } from 'react';

interface props {
    children?: ReactNode
    className?: string
}

const Box: React.FC<props> = ({ children, className = ''}) => {
    return (
        <div className={'Box flex flex-col items-center shadow-lg rounded-2xl mb-sm md:mb-md mx-auto px-sm py-md md:p-lg bg-white ' + className}>
            {children}
        </div>
    );
}

export default Box;