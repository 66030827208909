import React, {useState, useEffect} from 'react';
import Select from 'react-select';

interface Option {
    value: string;
    label: string;
}

interface props {
    name: string
    placeholder: string
    value: string
    error?: boolean
    className?: string
    options: Option[]
    onChange: (name: string, value: string) => void
}

const CustomSelect: React.FC<props> = (
    {
        name,
        placeholder,
        value,
        error = false,
        className = '',
        options,
        onChange
    }) => {

    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    useEffect(() => {
        const option = options.find(option => option.value === value);
        setSelectedOption(option || null);
    }, [value, options]);

    const handleChange = (selectedOption: Option | null) => {
        setSelectedOption(selectedOption);
        if (selectedOption !== null) {
            onChange(name, selectedOption.value);
        } else {
            onChange(name, '');
        }
    };

    return (
        <Select
            classNames={{
                container: () => 'CustomSelect inline-block text-primary rounded-md w-52 sm:w-80 max-w-full mb-sm sm:mb-md ' + (error && 'errorBorder ') + className,
                control: () =>
                    'sm:!p-xs !border-0 !shadow-input !rounded-md !cursor-pointer',
                placeholder: () => '!text-tertiary',
                singleValue: () => '!text-primary',
                option: (state) =>
                    '!p-xs ' +
                    (state.isSelected ? '!text-white !bg-primary ' : '!text-primary ') +
                    (state.isDisabled ? '!text-tertiary !cursor-default ' : '!cursor-pointer ')
            }}
            classNamePrefix="CustomSelect"
            name={name}
            placeholder={placeholder}
            options={options}
            value={selectedOption}
            onChange={handleChange}
        />
    );
}

export default CustomSelect;