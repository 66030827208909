import React from "react";
import moment from 'moment';
import {useTranslation} from "react-i18next";
import KeyValueTable from '../../components/KeyValueTable/KeyValueTable';
import KeyValueText from "../KeyValueText/KeyValueText";
import {formatPrice} from "../../../utils/price";

interface props {
    objectData: any
    configurationData: any
    showTable?: boolean
    tableStyle?: number
    className?: string
}

interface Fee {
    key: string;
    name: string;
    value: number;
}

const ConfigurationData: React.FC<props> = ({objectData, configurationData, showTable = true, tableStyle = 1, className = ''}) => {
    const { t } = useTranslation('onboarding');

    let installFee: Fee | undefined = undefined;

    if(objectData.investmentOption === 'User buy/rent') {
        if (configurationData.extensionState === 'B' || configurationData.extensionState === 'C1') {
            installFee = objectData.fees.find((fee: Fee) => fee.key === "installC1");
        } else if (configurationData.extensionState === 'C2') {
            installFee = objectData.fees.find((fee: Fee) => fee.key === "installC2");
        }
    }

    const configurationArr: {[p: string]: any} = {};

    if (configurationData.parkingSpotName?.length > 0) {
        configurationArr[t('configuration.parkingSpot.label')] = configurationData.parkingSpotName;
    }

    if (installFee?.value !== undefined) {
        configurationArr[t('object.installFee')] = formatPrice(installFee?.value);
    }

    if (configurationData.investPrice?.length > 0) {
        configurationArr[t('object.investPrice')] = formatPrice(configurationData.investPrice) + (configurationData.investOption === 'rent' ? t('general.perMonth') : '');
    }

    if (configurationData.activationDate?.length > 0) {
        configurationArr[t('configuration.activationDate.label')] = moment(configurationData.activationDate).format('DD.MM.YYYY');
    }

    if(Object.keys(configurationArr).length > 0) {
        return (
            <>
                {showTable ? (
                    <KeyValueTable className={className} title={t('configuration.lead')} data={configurationArr} style={tableStyle}/>
                ) : (
                    <KeyValueText className={className} title={t('configuration.lead')} data={configurationArr}/>
                )}
            </>
        );
    } else {
        return null;
    }
}

export default ConfigurationData;