import React from 'react'
import Message from '../Message/Message';

export type props = {
    errors: {[key: string]: string}
}

const ErrorMessages: React.FC<props> = ({ errors }) => {

    const errorsArray = Object.values(errors).filter(value => value !== '');

    if (errorsArray.length === 0) return <></>;

    return (
        <Message type={'warning'}>
            {errorsArray.map((error, index) => (
                <p key={index}>{error}</p>
            ))}
        </Message>
    )
}

export default ErrorMessages
