import React from "react";
import {formatPrice} from "../../../utils/price";
import {useTranslation} from "react-i18next";
import KeyValueTable from '../../components/KeyValueTable/KeyValueTable';
import KeyValueText from "../KeyValueText/KeyValueText";

interface props {
    objectData: any
    showTable?: boolean
    tableStyle?: number
    className?: string
}

interface Fee {
    key: string;
    name: string;
    value: number;
}

const ObjectData: React.FC<props> = ({objectData, showTable = true, tableStyle = 1, className = ''}) => {
    const { t } = useTranslation('onboarding');

    const productMonth = objectData.fees.find((fee: Fee) => fee.key === "productMonth");
    const productkwh = objectData.fees.find((fee: Fee) => fee.key === "productkwh");
    const activationFee = objectData.fees.find((fee: Fee) => fee.key === "activation");

    const productFee = (
        <div className='flex flex-col gap-y-0.5'>
            {productMonth?.value ? (
                <div>
                    {formatPrice(productMonth.value) + t('general.perMonth')}
                </div>
            ) : ''}
            {productkwh?.value ? (
                <div>
                    {formatPrice(productkwh.value) + t('general.perkWh')}<br/>
                    <div className='text-sm'>{t('object.tariffIncl')}</div>
                </div>
            ) : ''}
        </div>
    );

    const objectArr = {
        // [t('object.objectname')]: objectData.name ?? '',
        [t('object.mygridProduct')]: objectData.product?.name ?? '',
        [t('object.productFee')]: productFee,
        [t('object.chargingTariff')]: objectData.tariff?.price ? formatPrice(objectData.tariff.price) + t('general.perkWh') : '?',
        [t('object.activationFee')]: activationFee?.value ? formatPrice(activationFee.value) : '?',
    };

    return (
        <>
            {showTable ? (
                <KeyValueTable className={className} title={objectData.name ?? t('object.data.text')} data={objectArr} style={tableStyle}/>
            ) : (
                <KeyValueText className={className} title={objectData.name ?? t('object.data.text')} data={objectArr}/>
            )}
        </>
    );
}

export default ObjectData;