import i18n from 'i18next'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'

import { isDate } from '../utils/date'

import { de, en, fr } from './resources'

const resources = {
	'de-CH': de,
	de,
	'fr-CH': fr,
	fr,
	'en-CH': en,
	en,
} as const

type Language = keyof typeof resources
type Namespace = keyof (typeof resources)[Language]

const ns: Namespace[] = Object.keys(Object.values(resources)[0]) as Namespace[]
export const defaultNS = ns[0]

i18n.use(initReactI18next)
	.init({
		ns,
		defaultNS,
		resources: {
			...Object.entries(resources).reduce(
				(acc, [key, value]) => ({
					...acc,
					[key]: value,
				}),
				{},
			),
		},
		lng: 'de-CH',
		fallbackLng: ['en-CH', 'en'],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		compatibilityJSON: 'v3',
	})

// i18n.services.formatter?.add('duration', (value, _lng, _options) => {
// 	if (value <= 0) return '--'
// 	const duration = moment.duration(value, 'minutes').format(() => {
// 		if (moment.duration(value, 'minutes') < moment.duration(1, 'hour')) return 'mm[m] ss[s]'
// 		return '*h[h] mm[m] ss[s]'
// 	})
//
// 	return duration
// })

i18n.services.formatter?.add('dash-zero-value', (value, _lng, _options) => {
	if (value <= 0.0) return '--'
	return value
})

i18n.services.formatter?.add('dash-infinit-date-value', (value, _lng, _options) => {
	if (!value) return '--'

	if (isDate(value)) {
		const now = Date.now()
		const diff = +value - now

		const hundredYears = 100 * 365 * 24 * 60 * 60 * 1000
		if (diff > hundredYears) return '--'
	}

	return value
})

i18n.services.formatter?.add('currency-symbol', (value, lng, _options) => {
	const symbol = (0)
		.toLocaleString(lng, { style: 'currency', currency: value, minimumFractionDigits: 0, maximumFractionDigits: 0 })
		.replace(/\d/g, '')
		.trim()

	return symbol
})

export default i18n
export const getCurrentLng = () => i18n.language
