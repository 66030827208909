import { useState } from 'react';

interface Data {
    [fieldName: string]: string;
}

const FormData = (initialFormData: Data) => {
    const [formData, setFormData] = useState<Data>( initialFormData || {});

    const setDataField = (fieldName: string, DataMessage: string) => {
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: DataMessage,
        }));
    };

    const unsetDataField = (fieldName: string) => {
        setFormData(prevData => {
            delete prevData[fieldName];
            return { ...prevData };
        });
    };

    const resetFormData = () => {
        setFormData(initialFormData || {});
    };

    return {
        formData,
        setDataField,
        unsetDataField,
        resetFormData
    };
};

export default FormData;