import React, {
    useCallback,
    useState,
} from 'react';
import { produce } from 'immer';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import Box from "../../components/Box/Box";
import { FormStateContext } from "../../context/FormStateContext";
import { FORM_STATE } from "../../context/FormStateContext";
import {useTranslation} from "react-i18next";

const TaskMultiStepFormContainer = () => {

    const { t } = useTranslation('onboarding');

    const [form, setForm] = useState(FORM_STATE);

    const next = useCallback(() => {
        setForm(
            produce((form) => {
                form.selectedIndex += 1;
            })
        );
    }, [setForm]);

    const prev = useCallback(() => {
        setForm(
            produce((form) => {
                form.selectedIndex -= 1;
            })
        );
    }, [setForm]);

    const setSelectedIndex = useCallback(
        (index: number) => {
            setForm(
                produce((form) => {
                    form.selectedIndex = index;
                })
            );
        },
        [setForm]
    );

    const selectedIndex = form.selectedIndex;

    return (
        <FormStateContext.Provider value={{
            form,
            setForm,
        }}
        >
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Box>
                <Tab.List className={'Stepper flex items-start w-full justify-between flex-col md:flex-row'}>
                    {Object.entries(form.steps).map(([stepKey, step], index) => {
                        const canSelectStep = Object.values(form.steps)
                            .slice(0, index)
                            .every((step) => step.valid);
                        const canSelectNextStep = Object.values(form.steps)
                            .slice(0, index + 1)
                            .every((step) => step.valid);

                        return (
                            <React.Fragment key={index}>
                                <Tab
                                    className={classNames({
                                        'Step group flex items-center p-0 md:px-sm text-gray-300 transition-colors': true,
                                        'Step--completed !text-secondaryDark': index < selectedIndex, /* blue-600 */
                                        'Step--disabled hidden md:block md:opacity-0': !canSelectStep,
                                        'Step--active !text-secondaryDark': index === selectedIndex,
                                        'hover:!text-secondary': canSelectStep
                                    })}
                                    disabled={!canSelectStep}>
                                    <div className='flex items-center gap-x-xs flex-row md:flex-col'>
                                        <span
                                            className={classNames({
                                                'flex items-center justify-center w-8 h-8 text-md font-bold rounded-full shrink-0 text-gray-300 border-2 border-gray-300 transition-colors md:mb-1': true,
                                                '!border-secondaryDark !text-secondaryDark': index <= selectedIndex,
                                                'group-hover:!border-secondary group-hover:!text-secondary': canSelectStep
                                            })}>{index + 1}</span>
                                        <span className='font-semibold'>{t(step.labelField)}</span>
                                    </div>
                                </Tab>
                                {index + 1 < Object.keys(form.steps).length &&
                                    <div
                                        className={classNames({
                                            'bg-gray-300 w-0.5 h-sm ml-sm -translate-x-1/2 md:w-20 md:h-0.5 md:ml-0 md:mt-sm md:-translate-x-0 md:-translate-y-1/2': true,
                                            '!bg-secondaryDark': index < selectedIndex,
                                            'hidden md:block md:opacity-0': !canSelectNextStep,
                                        })}></div>
                                }
                            </React.Fragment>
                        );
                    })}
                </Tab.List>
            </Box>
            <Tab.Panels>
                {Object.entries(form.steps).map(([stepKey, step], index) => {
                    const FormComponent: React.FC<{ onNext: () => void; onPrev: () => void }> = step.form;
                    return (
                        <Tab.Panel key={index}>
                            <FormComponent onNext={next} onPrev={prev} />
                            { false &&
                                <Box>
                                    <pre style={{ textAlign: 'left', width: '100%' }}>{JSON.stringify(form.steps, null, 2)}</pre>
                                </Box>
                            }
                        </Tab.Panel>
                    );
                })}
            </Tab.Panels>
        </Tab.Group>
        </FormStateContext.Provider>
    );
};

export default TaskMultiStepFormContainer;