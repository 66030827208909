import React, {FormEvent, useContext} from "react";
import { produce } from 'immer';
import {useTranslation} from "react-i18next";
import useFormErrors from "../../../../utils/FormErrors";
import Box from "../../../components/Box/Box";
import LayoutBox from "../../../components/Box/LayoutBox";
import ErrorMessages from "../../../components/ErrorMessage/ErrorMessages";
import Button from "../../../components/Button/Button";
import ObjectData from "../../../components/ObjectData/ObjectData";
import AccessoriesData from "../../../components/AccessoriesData/AccessoriesData";
import {FormStateContext} from "../../../context/FormStateContext";
import Input from "../../../components/Input/Input";
import useFormData from "../../../../utils/FormData";
import Checkbox from "../../../components/Checkbox/Checkbox";
import ConfigurationData from "../../../components/ConfigurationData/ConfigurationData";
import OrderBox from "../../../components/OrderBox/OrderBox";

interface props {
    onNext: () => void;
    onPrev: () => void;
}

const BillingForm: React.FC<props> = ({ onNext, onPrev }) => {
    const { t } = useTranslation('onboarding');

    const { errors, setError, resetErrors, isValid } = useFormErrors();

    const { form, setForm } = useContext(FormStateContext);

    const { formData, setDataField } = useFormData({
        ...form.steps.account.billingAddress,
        ...Object.fromEntries(
            Object.entries(form.steps.billing.value).filter(([key, value]) => value !== null && value !== undefined && value !== '')
        )
    });

    const onChange = (inputName: string, newValue: string) => {
        setDataField(inputName, newValue);
    };

    const objectData = form.steps.object.data;

    const addressFields = ['firstname', 'lastname', 'street', 'city', 'postalCode', 'country'];

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        /* Start - Validation */
        resetErrors();

        addressFields.forEach(field => {
            !formData[field] && setError(field, t(`billing.${field}.errors.empty`));
        });

        if(formData.shippingAddress === "true") {
            addressFields.forEach(field => {
                !formData['shipping_'+field] && setError('shipping_'+field, t(`billing.${field}.errors.empty`));
            });
        }
        /* End - Validation */

        if(isValid()) {
            setForm(
                produce((formState) => {
                    formState.steps.billing = {
                        ...formState.steps.billing,
                        valid: true,
                        value: {
                            firstname: formData.firstname,
                            lastname: formData.lastname,
                            street: formData.street,
                            city: formData.city,
                            postalCode: formData.postalCode,
                            country: formData.country,
                            shippingAddress: formData.shippingAddress,
                            shipping_firstname: formData.shippingAddress === "true" ? formData.shipping_firstname : formData.firstname,
                            shipping_lastname: formData.shippingAddress === "true" ? formData.shipping_lastname : formData.lastname,
                            shipping_street: formData.shippingAddress === "true" ? formData.shipping_street : formData.street,
                            shipping_city: formData.shippingAddress === "true" ? formData.shipping_city : formData.city,
                            shipping_postalCode: formData.shippingAddress === "true" ? formData.shipping_postalCode : formData.postalCode,
                            shipping_country: formData.shippingAddress === "true" ? formData.shipping_country : formData.country
                        }
                    };
                })
            );
            onNext();
        }
    };

    return (
        <div className='flex gap-x-md flex-col lg:flex-row'>
            <div className='w-full lg:w-7/12'>
                <LayoutBox heading={t('billing.lead')}>
                    <form onSubmit={onSubmit} className='w-full max-w-500 text-left'>
                        <ErrorMessages errors={Object.fromEntries(
                            Object.entries(errors)
                                .filter(([key, value]) => !key.startsWith('shipping_'))
                        )}/>
                        <div className='mb-sm md:mb-md'>{t('billing.text')}</div>
                        {
                            addressFields.map(field => (
                                <Input
                                    name={field}
                                    placeholder={t(`billing.${field}.label`)}
                                    value={formData[field]}
                                    error={!!errors[field]}
                                    type='text'
                                    onChange={onChange}
                                    key={field}
                                />
                            ))
                        }
                        <Checkbox
                            name='shippingAddress'
                            label={t('billing.shipping.checkbox')}
                            checked={formData.shippingAddress === "true"}
                            error={!!errors.shippingAddress}
                            onChange={onChange}
                        />
                        {formData.shippingAddress === "true" &&
                            <>
                                <ErrorMessages errors={Object.fromEntries(
                                    Object.entries(errors)
                                        .filter(([key, value]) => key.startsWith('shipping_'))
                                )}/>
                                <div className='mb-md'>{t('billing.shipping.text')}</div>
                                {
                                    addressFields.map(field => (
                                        <Input
                                            name={'shipping_'+field}
                                            placeholder={t(`billing.${field}.label`)}
                                            value={formData['shipping_'+field]}
                                            error={!!errors['shipping_'+field]}
                                            type='text'
                                            onChange={onChange}
                                            key={'shipping_'+field}
                                        />
                                    ))
                                }
                            </>
                        }
                        <Button
                            text={t('general.continue')}
                            type='submit'
                        />
                    </form>
                </LayoutBox>
            </div>
            <div className='w-full lg:w-5/12'>
                <OrderBox objectData={objectData} configurationData={form.steps.configuration.value}/>
            </div>
        </div>
    );
}

export default BillingForm;