import React from "react";
import {useTranslation} from "react-i18next";
import LayoutBox from "../Box/LayoutBox";
import ObjectData from "../ObjectData/ObjectData";
import ConfigurationData from "../ConfigurationData/ConfigurationData";
import AccessoriesData from "../AccessoriesData/AccessoriesData";

interface props {
    objectData: any
    configurationData: any
    className?: string
}

const OrderBox: React.FC<props> = ({objectData, configurationData, className = ''}) => {
    const { t } = useTranslation('onboarding');

    return (
        <LayoutBox heading={t('order.lead')}>
            <ObjectData objectData={objectData}/>
            <ConfigurationData className='mt-md' objectData={objectData} configurationData={configurationData}/>
            <AccessoriesData className='mt-md' configurationData={configurationData}/>
        </LayoutBox>
    );
}

export default OrderBox;