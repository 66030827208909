import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer
            className="Footer relative flex flex-col-reverse xs:flex-row items-center bg-primary text-white py-sm md:py-md rounded-tr-2xl md:rounded-tr-4xl min-h-16 md:min-h-24 shadow-lg">
            <div className="xs:!w-auto container container--nomaxwidth xs:absolute xs:left-0 xs:top-1/2 xs:-translate-y-1/2">
                <div className="text-sm whitespace-nowrap ">
                    © {currentYear} mygrid AG
                </div>
            </div>
            <div className="container mb-xs xs:mb-0 xs:text-right">
                <a className='text-white hover:text-secondary' href='mailto:support@mygrid.ch'>support@mygrid.ch</a>
            </div>
        </footer>
    );
}

export default Footer;