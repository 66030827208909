import React, {useEffect} from 'react';
import { useLanguage } from './utils/lang';
import Header from "./templates/pageelements/Header/Header";
import Main from "./templates/pageelements/Main/Main";
import Footer from "./templates/pageelements/Footer/Footer";

function App() {
    const { changeLanguage } = useLanguage();

    useEffect(() => {
        const browserLanguage = navigator.language;
        changeLanguage(browserLanguage);
    }, []);

    return (
        <>
            <Header/>
            <Main/>
            <Footer/>
        </>
    );
}

export default App;