import React, {ReactNode} from 'react';

interface props {
    name: string
    label?: ReactNode
    placeholder?: string
    value: string
    error?: boolean
    type?: string
    autocomplete?: string
    className?: string
    onChange: (inputName: string, newValue: string) => void
}

const Input: React.FC<props> = (
    {
        name,
        label= '',
        placeholder= '',
        value,
        error = false,
        type= 'text',
        autocomplete= 'on',
        className= '',
        onChange,
    }) => {

    const handleChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = target;
        onChange(name, value);
    };

    return (
        <div className={`Input [text-align:inherit] ${className && className}`}>
            {label && <label className='block mb-xs [text-align:inherit]' htmlFor={name}>{label}</label>}
            <input
                className={'text-primary placeholder:text-tertiary bg-white shadow-input rounded-md w-52 sm:w-80 max-w-full min-h-[36px] sm:min-h-[58px] mb-sm sm:mb-md [text-align:inherit] ' + (error && 'errorBorder')}
                name={name}
                value={value}
                type={type}
                max={type === 'date' ? '2999-12-31' : undefined}
                placeholder={placeholder}
                autoComplete={autocomplete}
                onChange={handleChange}
            />
        </div>
    );
}

export default Input;