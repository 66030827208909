import React from 'react';
import classNames from "classnames";

interface props {
    text: string
    type?: 'button' | 'submit' | 'reset';
    loading?: boolean
    small?: boolean
    onClick?: () => void
    disabled?: boolean
}

const Button: React.FC<props> = ({ text, type = 'button', loading = false, small = false, onClick = undefined, disabled = false}) => {
    return (
        <button
            type={type}
            className={classNames({
                'Button relative text-center text-white shadow-lg rounded-md w-52 sm:w-80 max-w-full cursor-pointer bg-primary animatedBackground': true,
                'cursor-default bg-disabled': disabled,
                '!text-transparent': loading,
                '!w-30 sm:!w-40': small,
            })}
            onClick={onClick}>
            {loading && <div className='loadingCircle'></div>}
            {text}
        </button>
    );
}

export default Button;