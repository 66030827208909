import React, {FormEvent, useContext, useState} from "react";
import { produce } from 'immer';
import {Trans, useTranslation} from "react-i18next";
import useFormErrors from "../../../../utils/FormErrors";
import LayoutBox from "../../../components/Box/LayoutBox";
import ErrorMessages from "../../../components/ErrorMessage/ErrorMessages";
import Button from "../../../components/Button/Button";
import Card from "../../../components/Card/Card";
import ObjectData from "../../../components/ObjectData/ObjectData";
import ConfigurationData from "../../../components/ConfigurationData/ConfigurationData";
import AccessoriesData from "../../../components/AccessoriesData/AccessoriesData";
import CostData from "../../../components/CostData/CostData";
import {FormStateContext} from "../../../context/FormStateContext";
import useFormData from "../../../../utils/FormData";
import Checkbox from "../../../components/Checkbox/Checkbox";
// import iconSuccess from "../../../../assets/images/icons/success.svg";
// import iconSuccess from "../../../../assets/images/chargers/generic@3x.png";
import iconSuccess from "../../../../assets/images/logo_icon.png";
import {API_URL} from "../../../../utils/constants";

interface props {
    onNext: () => void;
    onPrev: () => void;
}

const ConfirmationForm: React.FC<props> = ({ onNext, onPrev }) => {
    const { t } = useTranslation('onboarding');

    const { errors, setError, resetErrors, isValid } = useFormErrors();

    const [ loading, setLoading] = useState(false);

    const { form, setForm } = useContext(FormStateContext);

    const { formData, setDataField } = useFormData(form.steps.confirmation.value);

    const onChange = (inputName: string, newValue: string) => {
        setDataField(inputName, newValue);
    };

    const objectData = form.steps.object.data;

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        /* Start - Validation */
        resetErrors();

        // Privacy Policy
        formData.privacyPolicy !== "true" && setError('privacyPolicy', t(`confirmation.privacyPolicy.errors.empty`));

        /* End - Validation */
        if (isValid()) {
            setLoading(true);

            try {
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        object: { value: form.steps.object.value},
                        configuration: { value: form.steps.configuration.value},
                        account: { value: form.steps.account.value},
                        billing: { value: form.steps.billing.value},
                    })
                };
                const responseOnboarding = await fetch(`${API_URL}/api/onboarding`, requestOptions);
                if (!responseOnboarding.ok) {
                    setError('general', t('general.error'));
                } else {
                    const dataOnboarding = await responseOnboarding.json();
                    // Check if onboarding was successful
                    if (dataOnboarding.success) {
                        setForm(
                            produce((formState) => {
                                formState.steps.confirmation = {
                                    ...formState.steps.confirmation,
                                    valid: true
                                };
                            })
                        );
                    } else {
                        switch (dataOnboarding.error) {
                            case 'Invalid Site':
                                setError('general', t('general.error'));
                                break;
                            default:
                                setError('general', t('general.error'));
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Onboarding error:', error);
                setError('general', t('general.error'));
            } finally {
                setLoading(false);
            }
        }
    };

    if (!form.steps.confirmation.valid) {
        return (
            <div className='flex gap-md'>
                <div className='w-full'>
                    <LayoutBox heading={t('confirmation.lead')}>
                        <form onSubmit={onSubmit} className='w-full max-w-500 text-left'>
                            <div className='mb-sm'>{t('confirmation.mwst')}</div>
                            <ObjectData tableStyle={2} objectData={objectData}/>
                            <ConfigurationData className='mt-md' tableStyle={2} objectData={objectData}
                                               configurationData={form.steps.configuration.value}/>
                            <AccessoriesData className='mt-md' tableStyle={2}
                                             configurationData={form.steps.configuration.value}/>
                            <CostData className='mt-md' tableStyle={2} objectData={objectData}
                                      configurationData={form.steps.configuration.value}/>
                            <div className='mt-sm'>{t('confirmation.invoiceInitialCosts')}</div>
                            <div className='my-md'>
                                <Card>
                                    <div className='mb-1 md:mb-xs font-bold text-primary'>{t('account.lead')}</div>
                                    <div className='text-tertiary'>
                                        {form.steps.account.value.email}
                                    </div>
                                </Card>
                                <Card className='mt-sm'>
                                    <div className='mb-1 md:mb-xs font-bold text-primary'>{t('billing.label')}</div>
                                    <div className='text-tertiary'>
                                        <p>{form.steps.billing.value.firstname} {form.steps.billing.value.lastname}</p>
                                        <p>{form.steps.billing.value.street}</p>
                                        <p>{form.steps.billing.value.postalCode} {form.steps.billing.value.city}</p>
                                        <p>{form.steps.billing.value.country}</p>
                                    </div>
                                </Card>
                                {form.steps.billing.value.shippingAddress === 'true' &&
                                    <Card className='mt-sm'>
                                        <div
                                            className='mb-1 md:mb-xs font-bold text-primary'>{t('billing.shipping.lead')}</div>
                                        <div className='text-tertiary'>
                                            <p>{form.steps.billing.value.shipping_firstname} {form.steps.billing.value.shipping_lastname}</p>
                                            <p>{form.steps.billing.value.shipping_street}</p>
                                            <p>{form.steps.billing.value.shipping_postalCode} {form.steps.billing.value.shipping_city}</p>
                                            <p>{form.steps.billing.value.shipping_country}</p>
                                        </div>
                                    </Card>
                                }
                            </div>
                            <ErrorMessages errors={errors}/>
                            <Checkbox
                                name='privacyPolicy'
                                label={
                                    <Trans i18nKey="confirmation.privacyPolicy.text" t={t} components={[
                                        <a href='https://www.mygrid.ch/agb' target='_blank'/>,
                                        <a href='https://www.mygrid.ch/datenschutz' target='_blank'/>
                                    ]}/>
                                }
                                checked={formData.privacyPolicy === "true"}
                                error={!!errors.privacyPolicy}
                                onChange={onChange}
                            />
                            <Button
                                text={t('confirmation.button')}
                                loading={loading}
                                type='submit'
                            />
                        </form>
                    </LayoutBox>
                </div>
            </div>
        );
    } else {
        return (
            <div className='flex gap-md'>
                <div className='w-full'>
                    <LayoutBox heading={t('confirmation.success.lead')}>
                        <div className='w-full max-w-500'>
                            <img className="mb-sm mx-auto max-w-full w-[40px] md:w-[60px]" src={iconSuccess} alt="Success"/>
                            <div className='mb-md'>{t('confirmation.success.text')}</div>
                        </div>
                    </LayoutBox>
                </div>
            </div>
        );
    }
}

export default ConfirmationForm;