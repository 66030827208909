import React, { ReactNode } from 'react';

interface props {
    children?: ReactNode
    className?: string
}

const Card: React.FC<props> = ({ children, className = '' }) => {
    return (
        <div className={'Card color-primary bg-card p-xs md:p-sm rounded-lg ' + className}>
            {children}
        </div>
    );
}

export default Card;