import React, { ReactPortal } from 'react';

interface props {
    data: { [key: string]: any }
    title?: string
    priceTable?: boolean
    className?: string
}

const KeyValueText: React.FC<props> = ({ data, title, priceTable = false, className = '' }) => {

    return (
        <div className={'KeyValueText w-full ' + className}>
            {title && <div className='mb-xs font-bold text-primary'>{title}</div>}
            <div className='mb-sm'>
                {Object.entries(data).map(([key, value]) => (
                    (value !== null && !Array.isArray(value)) && (
                        <p key={key}><b>{key}:</b> {value}</p>
                    )
                ))}
            </div>
        </div>
    );
}

export default KeyValueText;