import { useState, useEffect  } from 'react';

interface Errors {
    [fieldName: string]: string;
}

const FormErrors = () => {
    const [errors, setErrors] = useState<Errors>( {});
    let validation = true;

    const setError = (fieldName: string, errorMessage: string) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: errorMessage,
        }));
        validation = false;
    };

    const unsetError = (fieldName: string) => {
        setErrors(prevErrors => {
            delete prevErrors[fieldName];
            return { ...prevErrors };
        });
        validation = true;
    };

    const getError = (fieldName: string) => {
        return errors[fieldName] ?? '';
    };

    const resetErrors = () => {
        setErrors({});
    };

    const isValid = () => {
        return validation;
    };

    return {
        errors,
        setError,
        unsetError,
        resetErrors,
        isValid
    };
};

export default FormErrors;