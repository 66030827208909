import React, { ReactNode } from 'react';
import Box from './Box';

interface props {
    heading?: string
    children?: ReactNode
}

const LayoutBox: React.FC<props> = ({ heading, children}) => {
    return (
        <Box>
            {heading && <>
                <h2 className="Box__heading text-lg md:text-3xl text-center mb-0 w-full max-w-500">{heading}</h2>
                <div className="Box__separator bg-primary mt-sm md:mt-md mb-md md:mb-lg mx-auto w-24 h-0.5"></div>
            </>}
            <div className="Box__content flex flex-col items-center text-center w-full">
                {children}
            </div>
        </Box>
    );
}

export default LayoutBox;