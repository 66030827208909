import React, {FormEvent, useContext} from "react";
import { produce } from 'immer';
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import {useTranslation, Trans} from "react-i18next";
import useFormErrors from "../../../../utils/FormErrors";
import LayoutBox from "../../../components/Box/LayoutBox";
import ErrorMessages from "../../../components/ErrorMessage/ErrorMessages";
import Button from "../../../components/Button/Button";
import OrderBox from "../../../components/OrderBox/OrderBox";
import {FormStateContext} from "../../../context/FormStateContext";
import moment from 'moment';
import Input from "../../../components/Input/Input";
import useFormData from "../../../../utils/FormData";
import { accessories } from '../../../../utils/accessories';
import Checkbox from "../../../components/Checkbox/Checkbox";
import {formatPrice} from "../../../../utils/price";

interface props {
    onNext: () => void;
    onPrev: () => void;
}

interface ParkingSpot {
    id: number;
    name: string;
    extensionState: string;
    asset?: any;
}

interface Fee {
    key: string;
    name: string;
    value: number;
}

const ConfigurationForm: React.FC<props> = ({ onNext, onPrev }) => {
    const { t } = useTranslation('onboarding');

    const { errors, setError, resetErrors, isValid } = useFormErrors();

    const { form, setForm } = useContext(FormStateContext);

    const { formData, setDataField } = useFormData(form.steps.configuration.value);

    const onChange = (inputName: string, newValue: string) => {
        setDataField(inputName, newValue);
    };

    const objectData = form.steps.object.data;
    const parkingSpots = objectData.parkingSpots as ParkingSpot[];
    const investCategory = objectData.investmentOption;
    const chargingRental = (objectData.fees as Fee[]).find((fee: Fee) => fee.key === "chargingRental");
    const chargingPrice = (objectData.fees as Fee[]).find((fee: Fee) => fee.key === "chargingPrice");
    const earliestActivationDate = moment().add(2, 'weeks');

    let hasCharger = false;

    if(formData.parkingSpot) {
        let selectedParkingSpot = parkingSpots.find(spot => spot.id === parseInt(formData.parkingSpot));
        hasCharger = (selectedParkingSpot && selectedParkingSpot.asset && selectedParkingSpot.asset.type === "Charger");
    }

    const parkingSpotOptions = parkingSpots.map(parkingSpot => ({
        value: parkingSpot.id.toString(),
        label: parkingSpot.name
    }));

    parkingSpotOptions.sort((a, b) => a.label.localeCompare(b.label));

    const investOptions = [
        { value: 'rent', label: 'Mieten - ' + (chargingRental?.value ? formatPrice(chargingRental.value) + t('general.perMonth') : '?') },
        { value: 'buy', label: 'Kaufen - ' + (chargingPrice?.value ? formatPrice(chargingPrice.value) : '?'), isDisabled: hasCharger },
    ];

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        /* Start - Validation */
        resetErrors();

        // Parking Spot
        !formData.parkingSpot && setError('parkingSpot', t('configuration.parkingSpot.errors.empty'));

        if(investCategory === 'User buy/rent') {
            // Investment Option
            (formData.investOption !== 'buy' && formData.investOption !== "rent") && setError('investOption', t('configuration.investOption.errors.empty'));

            // Agreement Purchase Terms
            formData.investOption === 'buy' && formData.agreementPurchaseTerms !== 'true' && setError('agreementPurchaseTerms', t('configuration.agreementPurchaseTerms.errors.empty'));
        }

        // Activation Date
        if(!formData.activationDate) {
            setError('activationDate', t('configuration.activationDate.errors.empty'));
        } else if (!moment(formData.activationDate).isValid()) {
            setError('activationDate', t('configuration.activationDate.errors.invalid'));
        } else if (!moment(formData.activationDate).isSameOrAfter(earliestActivationDate, 'day')) {
            setError('activationDate', t('configuration.activationDate.errors.earliest') + earliestActivationDate.format('DD.MM.YYYY'));
        }

        /* End - Validation */

        if(isValid()) {
            let investOption = "";
            let investPrice = "";
            switch (investCategory) {
                case 'User buy/rent':
                    investOption = formData.investOption;
                    if (investOption === "rent") investPrice = chargingRental?.value.toString() ?? '';
                    if (investOption === "buy") investPrice = chargingPrice?.value.toString() ?? '';
                    break;
                case 'Rent':
                    investOption = "rent";
                    investPrice = chargingRental?.value.toString() ?? '';
                    break;
                case 'Invest through owner':
                    investOption = "owner";
                    break;
            }
            const targetParkingSpot = parkingSpots.find(spot => spot.id === parseInt(formData.parkingSpot));
            setForm(
                produce((formState) => {
                    formState.steps.configuration = {
                        ...formState.steps.configuration,
                        valid: true,
                        value: {
                            parkingSpot: formData.parkingSpot,
                            parkingSpotName: targetParkingSpot ? targetParkingSpot.name : '',
                            extensionState: targetParkingSpot ? targetParkingSpot.extensionState : '',
                            investOption: investOption,
                            investPrice: investPrice,
                            activationDate: formData.activationDate,
                            agreementPurchaseTerms: formData.agreementPurchaseTerms,
                            cable3m: formData.cable3m,
                            cable5m: formData.cable5m,
                            cable8m: formData.cable8m,
                            chargingCard: formData.chargingCard,
                            keychain: formData.keychain
                        }
                    };
                })
            );
            onNext();
        }
    };

    return (
        <div className='flex gap-x-md flex-col lg:flex-row'>
            <div className='w-full lg:w-7/12'>
                <LayoutBox heading={t('configuration.lead')}>
                    <form onSubmit={onSubmit} className='w-full max-w-500 text-left'>
                        <ErrorMessages errors={errors}/>
                        {investCategory === 'Rent' &&
                            <>
                                <div className='w-full max-w-500 mb-md text-left'>
                                    <Trans i18nKey="configuration.rentCosts" t={t} components={[<br/>]}/> <b className='text-primary whitespace-nowrap'>{chargingRental?.value ? formatPrice(chargingRental.value) + t('general.perMonth') : '?'}</b>
                                </div>
                            </>
                        }
                        <div className='mb-sm'>{t('configuration.parkingSpot.text')}</div>
                        <CustomSelect
                            name='parkingSpot'
                            placeholder={t('configuration.parkingSpot.label')}
                            value={formData.parkingSpot}
                            error={!!errors.parkingSpot}
                            options={parkingSpotOptions}
                            onChange={onChange}
                        />
                        {investCategory === 'User buy/rent' &&
                            <>
                                <div className='mb-sm'>{t('configuration.investOption.text')}</div>
                                <CustomSelect
                                    name='investOption'
                                    placeholder={t('configuration.investOption.label')}
                                    value={formData.investOption}
                                    error={!!errors.investOption}
                                    options={investOptions}
                                    onChange={onChange}
                                />
                                {formData.investOption === 'buy' &&
                                    <>
                                        <Checkbox
                                            name='agreementPurchaseTerms'
                                            label={
                                                <Trans i18nKey="configuration.agreementPurchaseTerms.text" t={t} components={[
                                                    <a href='https://www.mygrid.ch/_files/ugd/726548_aaf3d375b2604a56b5ecad5afd70dc15.pdf' target='_blank'/>
                                                ]}/>
                                            }
                                            checked={formData.agreementPurchaseTerms === "true"}
                                            error={!!errors.agreementPurchaseTerms}
                                            onChange={onChange}
                                        />
                                    </>
                                }
                            </>
                        }
                        <div className='mb-sm'>{t('configuration.activationDate.text')}</div>
                        <Input
                            name='activationDate'
                            placeholder='yyyy-mm-dd'
                            value={formData.activationDate}
                            error={!!errors.activationDate}
                            type='date'
                            onChange={onChange}
                        />
                        <div className='mb-sm'>{t('configuration.accessories.text')}</div>
                        {accessories.map((accessory, index) => (
                            <Checkbox
                                key={index}
                                name={accessory.field}
                                label={`${t(`configuration.accessories.${accessory.field}`)} (${formatPrice(accessory.price)})`}
                                checked={formData[accessory.field] === "true"}
                                error={!!errors[accessory.field]}
                                onChange={onChange}
                            />
                        ))}
                        <Button
                            text={t('general.continue')}
                            type='submit'
                        />
                    </form>
                </LayoutBox>
            </div>
            <div className='w-full lg:w-5/12'>
                <OrderBox objectData={objectData} configurationData={form.steps.configuration.value}/>
            </div>
        </div>
    );
}

export default ConfigurationForm;