import React from "react";
import {useTranslation} from "react-i18next";
import KeyValueTable from '../../components/KeyValueTable/KeyValueTable';
import KeyValueText from '../../components/KeyValueText/KeyValueText';
import { accessories } from '../../../utils/accessories';
import { formatPrice } from '../../../utils/price';

interface props {
    configurationData?: any
    showTable?: boolean
    tableStyle?: number
    className?: string
}

const AccessoriesData: React.FC<props> = ({configurationData = {}, showTable = true, tableStyle = 1, className = ''}) => {
    const { t } = useTranslation('onboarding');

    const accessoriesArr: {[p: string]: any} = {};

    accessories.forEach(accessory => {
        if (configurationData[accessory.field] === 'true') {
            accessoriesArr[t(`configuration.accessories.${accessory.field}`)] = formatPrice(accessory.price);
        }
    });

    if(Object.keys(accessoriesArr).length > 0) {
        return (
            <>
                {showTable ? (
                    <KeyValueTable
                        className={className}
                        title={t('configuration.accessories.label')}
                        data={accessoriesArr}
                        style={tableStyle}
                        priceTable={true}
                    />
                ) : (
                    <KeyValueText
                        className={className}
                        title={t('configuration.accessories.label')}
                        data={accessoriesArr}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
}

export default AccessoriesData;