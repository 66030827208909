import React from 'react';
import Onboarding from '../../screens/Onboarding/Onboarding';
import {useTranslation} from "react-i18next";


const Main = () => {
    const { t } = useTranslation('onboarding');

    return (
        <main>
            <div className="mainWrapper">
                <article>
                    <div className="container pt-md pb-sm md:py-xl">
                        <Onboarding />
                    </div>
                </article>
            </div>
        </main>
    );
}

export default Main;