import React, { ReactNode } from 'react';

interface props {
    children?: ReactNode
    type?: 'success' | 'error' | 'warning' // Tailwind Safelist
}

const Card: React.FC<props> = ({ children, type = 'success'}) => {
    return (
        <div className={`Message text-${type} bg-${type}Light flex flex-col text-sm mb-sm p-sm rounded-md gap-2`}>
            {children}
        </div>
    );
}

export default Card;