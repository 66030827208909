import React, {FormEvent, ReactNode, useContext, useState} from "react";
import { produce } from 'immer';
import { FormStateContext } from "../../../context/FormStateContext";
import { useTranslation, Trans } from "react-i18next";
import useFormErrors from "../../../../utils/FormErrors";
import LayoutBox from "../../../components/Box/LayoutBox";
import Input from "../../../components/Input/Input";
import ErrorMessages from "../../../components/ErrorMessage/ErrorMessages";
import Button from "../../../components/Button/Button";
import ObjectData from "../../../components/ObjectData/ObjectData";
import useFormData from "../../../../utils/FormData";
import {API_URL} from "../../../../utils/constants";

interface props {
    onNext: () => void;
    onPrev: () => void;
}

const ObjectForm: React.FC<props> = ({ onNext, onPrev }) => {
    const { t } = useTranslation('onboarding');

    const [loading, setLoading] = useState(false);

    const { form, setForm } = useContext(FormStateContext);

    const { errors, setError, resetErrors } = useFormErrors();

    const { formData, setDataField } = useFormData(form.steps.object.value);

    const onChange = (inputName: string, newValue: string) => {
        setDataField(inputName, newValue);
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await fetchObjectData();
    };

    const fetchObjectData = async () => {
        resetErrors();
        if (formData.objectNr) {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/site/onboarding/` + formData.objectNr.trim());
                if (!response.ok) {
                    setError('general', t('general.error'));
                } else {
                    const result = await response.json();
                    if(result.success) {
                        setForm(
                            produce((formState) => {
                                formState.steps.object = {
                                    ...formState.steps.object,
                                    valid: true,
                                    value: {
                                        objectNr: formData.objectNr.trim()
                                    },
                                    data: result.data
                                };
                            })
                        );
                    } else {
                        setError('objectNr', t('object.number.errors.wrong'));
                    }
                }
            } catch (error) {
                console.error('Onboarding error:', error);
                setError('general', t('general.error'));
            } finally {
                setLoading(false);
            }
        } else {
            setError('objectNr', t('object.number.errors.empty'));
        }
    };

    if(!form.steps.object.valid) {
        // show object Form
        return (
            <div className='flex gap-md'>
                <div className='w-full'>
                    <LayoutBox heading={t('object.form.lead')}>
                        <form className='w-full max-w-500' /* p-md rounded-lg bg-card */ onSubmit={onSubmit}>
                            <ErrorMessages errors={errors}/>
                            <div className='mb-md'>{t('object.number.text')}</div>
                            <Input
                                name='objectNr'
                                placeholder={t('object.number.label')}
                                value={formData.objectNr}
                                error={!!errors.objectNr}
                                type='text'
                                onChange={onChange}
                            />
                            <Button
                                text={t('object.form.button')}
                                loading={loading}
                                type='submit'
                            />
                        </form>
                    </LayoutBox>
                </div>
            </div>
        );
    } else {
        // show object data
        return (
            <div className='flex gap-md'>
                <div className='w-full'>
                    <LayoutBox heading={t('object.data.lead')}>
                        <form className='w-full max-w-500' onSubmit={onSubmit}>
                            <ObjectData className='mb-md' objectData={form.steps.object.data}/>
                            <Button
                                text={t('general.continue')}
                                type='submit'
                                onClick={onNext}
                            />
                        </form>
                    </LayoutBox>
                </div>
            </div>
        );
    }
}

export default ObjectForm;