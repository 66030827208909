import { useTranslation } from 'react-i18next';
export const useLanguage = () => {
    const { i18n, t } = useTranslation('onboarding');

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);

        document.documentElement.lang = lng.substring(0, 2);
        document.title = 'mygrid - ' + t('heading');
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', 'mygrid - ' + t('heading'));
        }
    }

    const getLanguage = () => i18n.language.substring(0, 2);

    return { changeLanguage, getLanguage };
};