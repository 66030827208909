import {createContext} from "react";
import ObjectForm from "../screens/Onboarding/forms/ObjectForm";
import ConfigurationForm from "../screens/Onboarding/forms/ConfigurationForm";
import AccountForm from "../screens/Onboarding/forms/AccountForm";
import BillingForm from "../screens/Onboarding/forms/BillingForm";
import ConfirmationForm from "../screens/Onboarding/forms/ConfirmationForm";


export const FORM_STATE = {
    selectedIndex: 0,
    steps: {
        object: {
            labelField: 'object.lead',
            form: ObjectForm,
            valid: false,
            value: {
                objectNr: '',
            },
            data: {
                parkingSpots: [],
                fees: [],
                investmentOption: ''
            }
        },
        configuration: {
            labelField: 'configuration.lead',
            form: ConfigurationForm,
            valid: false,
            value: {
                parkingSpot: '',
                parkingSpotName: '',
                extensionState: '',
                investOption: '',
                investPrice: '',
                activationDate: '',
                agreementPurchaseTerms: 'false',
                cable3m: 'false',
                cable5m: 'false',
                cable8m: 'false',
                chargingCard: 'false',
                keychain: 'false'
            },
        },
        account: {
            labelField: 'account.lead',
            form: AccountForm,
            valid: false,
            value: {
                email: '',
                method: '',
                language: ''
            },
            billingAddress: {
                firstname: '',
                lastname: '',
                street: '',
                city: '',
                postalCode: '',
                country: ''
            },
            data: {},
        },
        billing: {
            labelField: 'billing.lead',
            form: BillingForm,
            valid: false,
            value: {
                firstname: '',
                lastname: '',
                street: '',
                city: '',
                postalCode: '',
                country: '',
                shippingAddress: 'false',
                shipping_firstname: '',
                shipping_lastname: '',
                shipping_street: '',
                shipping_city: '',
                shipping_postalCode: '',
                shipping_country: ''
            },
        },
        confirmation: {
            labelField: 'confirmation.lead',
            form: ConfirmationForm,
            valid: false,
            value: {
            },
        },
    },
};

export const FormStateContext = createContext({
    form: FORM_STATE,
    setForm: (
        form: typeof FORM_STATE | ((form: typeof FORM_STATE) => typeof FORM_STATE)
    ) => {},
});