import React, {ReactNode, useState} from 'react';

interface props {
    name: string
    label: ReactNode
    checked?: boolean
    error?: boolean
    className?: string
    onChange: (inputName: string, newValue: string) => void
}

const Checkbox: React.FC<props> = (
    {
        name,
        label,
        checked = false,
        error = false,
        className= '',
        onChange,
    }) => {

    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = () => {
        if (!isChecked) {
            onChange(name, 'true');
        } else {
            onChange(name, 'false');
        }
        setIsChecked(!isChecked);
    };

    return (
        <div className={`Checkbox mb-sm sm:mb-md w-500 max-w-full flex flex-row gap-2 sm:gap-4 items-center ${className && className}`}>
            <input
                className={'relative text-primary bg-white shadow-input rounded-md w-5 sm:w-7 h-5 sm:h-7 shrink-0 cursor-pointer ' +
                    'before:absolute before:top-1/2 before:left-1/2  before:-translate-x-1/2 before:-translate-y-1/2 before:w-[3px] sm:before:w-1 before:h-4 sm:before:h-6 before:rounded-md before:bg-white before:transition-colors before:rotate-45 checked:before:bg-primary ' +
                    'after:absolute after:top-1/2 after:left-1/2  after:-translate-x-1/2  after:-translate-y-1/2 after:w-[3px] sm:after:w-1 after:h-4 sm:after:h-6 after:rounded-md after:bg-white after:transition-colors after:-rotate-45 checked:after:bg-primary ' +
                    (error && 'errorBorder')}
                name={name}
                checked={isChecked}
                type='checkbox'
                onChange={handleChange}
            />
            <label className='block cursor-pointer' htmlFor={name} onClick={handleChange}>{label}</label>
        </div>
    );
}

export default Checkbox;